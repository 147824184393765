import styled from "styled-components";
import DataRow from "../DataRow/DataRow";
import NextIcon from "../Icons/components/NextIcon";
import Collapse from "./Collapse";

const ClickableRow = styled.div`
    cursor: pointer;
    padding: 0;
`;

const Label = styled.div`
    line-height: 20px;
`;
const RotateIcon = styled.div`
    display: flex;
    align-items: center;
    & path:first-child {
        transition: all 0.4s;
        transform-origin: center center;
        transform: ${({ isOpen }) =>
        !!isOpen ? "rotateZ(90deg)" : "rotateZ(0deg)"};
    }
`;
const Description = styled.div`
    padding: 35px 0;
    white-space: pre-wrap;
    ul, ol {
        list-style: inside;
        ul {
            margin-bottom: 30px;
            li {
                margin-top: 30px;
            }
        }
        li {
            margin-bottom: 20px;
        }
    }
    .numeric {
        list-style: decimal;
        padding-left: 1rem;
    }
`;

const CollapseIcon = ({
    extraContent,
    openDefault,
    label = "Komu wynajmujemy",
    description = `Ten konkretny model to reedycja oryginalnego Princeton Silverface, który ogromną popularność zdobył wśród muzyków bluesowych. Dzięki niewielkiej mocy i w pełni lampowej konstrukcji brzmienie jest pełne i wyraziste. Piękny clean, a po podkręceniu głośności - delikatny przester, który śmiało nazwać można kwintesencją brzmienia Fendera. Wzmacniacz marzenie. Ci którzy mieli okazja na nim grać dobrze wiedza o czym piszemy. Jest w nim zaklęty klimat tamtych lat gdy rodziło się pojęcie brzmienia gitary elektrycznej. Wzmacniacz wyznaczył wie pewien standard do którego zawsze będą dążyć świadomi gitarzyści. Wzmacniacz bardzo dobrze współpracuje z zewnętrznymi efektami typu overdrive i fuzz, dzięki temu spokojnie można pokusi się o mocniejsze, alternatywne klimaty. Combo idealne do studia nagraniowego i na klubowe koncerty, jednak możliwość podpięcia dodatkowej kolumny sprawia, że Princeton bez większego problemu poradzi sobie na większych scenach. Ten kto nie chciałby takiego wzmacniacza po prostu najwyraźniej nie jest gitarzystą, a Kto go chce ten najwyraźniej jest ;)\n\nZapraszamy więc do zakupów.`,
}) => (
        <Collapse
            openDefault={openDefault}
            ActionComponent={({ toggleOpen, isOpen }) => (
                <ClickableRow onClick={toggleOpen}>
                    <DataRow
                        Icon={
                            <RotateIcon isOpen={isOpen}>
                                <NextIcon />
                            </RotateIcon>
                        }
                        label={<Label>{label}</Label>}
                    />
                </ClickableRow>
            )}
        >
            <Description>
                {extraContent}
                {description}
            </Description>
        </Collapse>
);

export default CollapseIcon;

import React from 'react'
import styled from 'styled-components'
import CurrencyIcon from '../Icons/components/CurrencyIcon'
import CalendarIcon from '../Icons/components/CalendarIcon'
import Tooltip from '../Tooltip/Tooltip'
import {
    selectMinimalMonthsParsed,
} from '../../features/basketSlice'
import { useSelector } from 'react-redux'
import { polishPlural } from '../../utils/functions'

const ProductCalculator = ({ data }) => {
    const months = useSelector(selectMinimalMonthsParsed);
    return (
        <StyledProductCalculator>
            <Row>
                <NameWrapper>
                    <CurrencyIcon className='icon' width={20} height={20} />
                    <Name>Rata miesięczna:</Name>
                </NameWrapper>
                <InstallmentBox>
                    <InstallmentGross>
                        {`${data.gross_month_cost} zł brutto`}
                    </InstallmentGross>
                    <InstallmentNet>
                        {`${data.net_month_cost} zł netto`}
                    </InstallmentNet>
                </InstallmentBox>
            </Row>
            <Row>
                <NameWrapper>
                    <CalendarIcon className='icon' width={20} height={20} />
                    <Name>Minimalny okres najmu</Name>
                </NameWrapper>
                <Price>
                    {months}{" "}
                    {polishPlural(
                        "Miesiąc",
                        "Miesiące",
                        "Miesięcy",
                        months
                    )}
                </Price>
            </Row>
            <Row>
                <NameWrapper>
                    <CurrencyIcon className='icon' width={20} height={20} />
                    <Name>
                        Jednorazowa kaucja zwrotna:
                    </Name>
                    <Tooltip description={'Jednorazowa kaucja zwrotna służy zabezpieczeniu kosztów ewentualnych uszkodzeń jakie mogą powstać w trakcie trwania umowy najmu lub zaległości w rozliczeniach z tytułu wynajmu. Kaucja jest zwracana na konto Klienta niezwłocznie po zakończeniu i rozliczeniu umowy najmu, a w przypadku wykupu sprzętu może być zaliczona na poczet ceny wykupu.'} />
                </NameWrapper>
                <Price>
                    {data.net_administrative_fee}&nbsp;zł
                </Price>
            </Row>

        </StyledProductCalculator>
    )
}

export default ProductCalculator

const StyledProductCalculator = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    .icon {
        color: ${props => props.theme.primary}
    }
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    min-height: 25px;
`

const InstallmentBox = styled.div`
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
 
`
const InstallmentGross = styled.div`
    font-weight: bold;
    font-size: 26px;
`
const InstallmentNet = styled.div`
    font-weight: bold;
    font-size: 14px;
    color: #798090;
`

const NameWrapper = styled.div`
    display: flex;
    gap: 10px;
`
const Name = styled.div`
    font-size: 17px;
`

const Price = styled.div`
    font-weight: bold;
    font-size: 18px;
`

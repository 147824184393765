import React from 'react'
import styled, { css } from 'styled-components'
import FlexBox from '../../../components/FlexBox/FlexBox'
import PlusIcon from '../../../components/Icons/components/PlusIcon'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import RecommendedProductsService from '../../../services/RecommendedProductsService'
import {
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../../features/basketSlice";
import { useSelector } from 'react-redux'
import RecommendedProduct from './RecommendedProduct'
import Spiner from '../../../components/Spiner/Spiner'

const RecommendedServices = () => {

    const shopUuid = useSelector(selectShopUuid);
    const mainProduct = useSelector(selectMainProduct);
    const minimalMonths = useSelector(selectMinimalMonthsParsed);


    const { data, isSuccess, isLoading } = useQuery(
        ['recommended-services', shopUuid, mainProduct.id, minimalMonths],
        RecommendedProductsService.getAllRecommendedProducts('ALL_SERVICES', shopUuid, minimalMonths, mainProduct.id, { limit: 4 })
    )


    return (
        <StyledRecommendedAccesories>
            <FlexBox justifyContent='space-between'>
                <Header>
                    <IconWrapper>
                        <PlusIcon width={20} height={20} />
                    </IconWrapper>
                    <HeaderText>Dodaj usługi</HeaderText>
                </Header>
                <FlexBox alignItems='center' gap="10px">
                    <StyledLink to='categories/all_services' disabled={isLoading}>
                        Wyświetl wszystkie usługi
                    </StyledLink>
                    <Icon />
                </FlexBox>
            </FlexBox>
            <ListWrapper isLoading={isLoading}>
                {
                    isLoading && <Spiner />
                }
                {
                    isSuccess && (
                        <>
                            {
                                data.items.length > 0 ? (
                                    <List>
                                        {
                                            data.items.map((item, index) => (
                                                <Item key={index}>
                                                    <Link key={index} to={`/products-collections/all_services/${item.details.id}`}>
                                                        <RecommendedProduct data={item} className='recommendedProduct' />
                                                    </Link>
                                                </Item>
                                            ))
                                        }
                                    </List>
                                ) : (
                                    <div>Nie znaleziono żadnych usług spełniających kryteria</div>
                                )
                            }
                        </>
                    )
                }
            </ListWrapper>
        </StyledRecommendedAccesories>
    )
}

export default RecommendedServices

const StyledRecommendedAccesories = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Icon = styled(PlusIcon)`
    color: ${props => props.theme.primary};
    font-size: 20px;
`

const disabledLinkStyle = css`
    pointer-events: none;
    opacity: 0.3;
`

const StyledLink = styled(Link)`
    text-decoration: underline;
    ${props => props.disabled && disabledLinkStyle}
`

const ListWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.isLoading && 'center'};
    min-height: 220px;
`

const List = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    .recommendedProduct {
        height: 100%;
    }
`

const Item = styled.div`
    flex: 0 1 190px;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

`
const IconWrapper = styled.div`
    color: ${props => props.theme.primary};
    display: flex;
`
const HeaderText = styled.div`
    font-size: 17px;
    font-weight: bold;
`

